import './../../UseCase.scss'
import Inside from './Component/inside'
import Exchange from './Component/exchange'
import Participate from './Component/participate'

export default function UsecaseDetails() {
  return (
    <>
      <div style={{ backgroundColor: '#f9f9f9' }}>
        <Inside />
        <Exchange />
        <Participate />
      </div>
    </>
  )
}
