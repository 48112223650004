// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BusinessApplication-section .swiper-wrapper {
  height: 330px;
}
.BusinessApplication-section .card {
  height: 300px !important;
  margin-right: 20px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/components/BusinessApplicationsSection/businessapplication.scss"],"names":[],"mappings":"AAGE;EACE,aAAA;AAFJ;AAKE;EACE,wBAAA;EACA,6BAAA;AAHJ","sourcesContent":[".BusinessApplication-section {\n\n\n  .swiper-wrapper {\n    height: 330px;\n  }\n\n  .card {\n    height: 300px !important;\n    margin-right: 20px !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
