// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FavoriteInfo {
  padding-right: 10px;
}

.favorite-card .card {
  width: 266px !important;
  height: 280px !important;
}

.slick-slider .slick-prev,
.slick-slider .slick-next,
.slick-slider .slick-next:before {
  display: block !important;
}

.slick-dots {
  margin: 0px;
  bottom: -25px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/frame/FavoriteSection/FavoriteSection.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAGE;EACE,uBAAA;EACA,wBAAA;AAAJ;;AAME;;;EAGE,yBAAA;AAHJ;;AAOA;EACE,WAAA;EACA,wBAAA;AAJF","sourcesContent":[".FavoriteInfo {\n  padding-right: 10px;\n}\n\n.favorite-card {\n  .card {\n    width: 266px !important;\n    height: 280px !important;\n  }\n}\n\n.slick-slider {\n\n  .slick-prev,\n  .slick-next,\n  .slick-next:before {\n    display: block !important;\n  }\n}\n\n.slick-dots {\n  margin: 0px;\n  bottom: -25px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
