import { Button } from '@quantumsurf/portal-shared-components'
import { t } from 'i18next'
import React from 'react'
import type { ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch'
import './../components/styles/overlay.scss'

export const Controls: React.FC<ReactZoomPanPinchContentRef> = ({
  zoomIn,
  zoomOut,
  resetTransform,
  centerView,
}: ReactZoomPanPinchContentRef) => (
  <div style={{ display: 'flex' }}>
    <Button
      type="button"
      className="overlay-button"
      onClick={() => {
        zoomIn()
      }}
      data-testid="zoom-in"
    >
      {t('global.zoom.zoomin')}
    </Button>
    <Button
      className="overlay-button"
      type="button"
      onClick={() => {
        zoomOut()
      }}
      data-testid="zoom-out"
    >
      {t('global.zoom.zoomout')}
    </Button>
    <Button
      className="overlay-button"
      type="button"
      onClick={() => {
        resetTransform()
      }}
      data-testid="reset"
    >
      {t('global.zoom.reset')}
    </Button>
    <Button
      className="overlay-button"
      type="button"
      onClick={() => {
        centerView()
      }}
      data-testid="center"
    >
      {t('global.zoom.center')}
    </Button>
  </div>
)
