// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.semantic-main section input {
  position: relative;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/SemanticHub/semantichub.scss"],"names":[],"mappings":"AAEI;EACE,kBAAA;EACA,iBAAA;AADN","sourcesContent":[".semantic-main {\n  section {\n    input {\n      position: relative;\n      margin-left: 10px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
