import { type SwiperHeaderTitleProps } from '../SwiperHeader'
import { Typography } from '@quantumsurf/portal-shared-components'

export const SwiperHeaderTitle = ({
  title,
  titleTextVariant = 'h2',
  titleStyle,
}: SwiperHeaderTitleProps) => {
  return (
    <>
      {title && (
        <Typography
          sx={{
            fontWeight: 'bold',
            marginLeft: '10px',
            color: '#000000',
          }}
          variant={titleTextVariant}
          style={titleStyle}
        >
          {title}
        </Typography>
      )}
    </>
  )
}
