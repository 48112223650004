import { Typography } from '@quantumsurf/portal-shared-components'
import './../../UseCase.scss'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

export default function UsecaseBusinessApplication() {
  const { t } = useTranslation('', {
    keyPrefix: 'content.useCase.businessapplication',
  })
  return (
    <>
      <div className="detailbox">
        <section className="contentbox">
          <div className="contentbox-text">
            <Typography
              style={{
                fontWeight: 'bold',
                margin: '20px 0px',
                textAlign: 'left',
              }}
              variant="h2"
            >
              {t('title')}
            </Typography>
            <div className="box" style={{ margin: '10px 10px' }}>
              <Box sx={{ margin: '10px 50px' }}>
                <Typography
                  variant="label2"
                  sx={{ whiteSpace: 'keep-all', fontWeight: 'bolder' }}
                >
                  {t('desc')}
                </Typography>
              </Box>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
