// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `fieldset {
  padding: 30px 50px;
  font-weight: bold;
  margin: 20px 40px;
}
fieldset div {
  white-space: keep-all;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/UseCase/components/detail/Component/inside.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,iBAAA;AACF;AAAE;EACE,qBAAA;AAEJ","sourcesContent":["fieldset {\n  padding: 30px 50px;\n  font-weight: bold;\n  margin: 20px 40px;\n  div {\n    white-space: keep-all;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
