// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppStoreSection-section .swiper-container-android .swiper-slide,
.AppStoreSection-section .swiper-wrapper {
  width: 100%;
  height: 280px;
}
.AppStoreSection-section .card {
  margin: 0px 5px;
  width: 100% !important;
  max-width: 800px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/components/AppStoreSection/appstoresection.scss"],"names":[],"mappings":"AACE;;EAEE,WAAA;EACA,aAAA;AAAJ;AAEE;EACE,eAAA;EACA,sBAAA;EACA,2BAAA;AAAJ","sourcesContent":[".AppStoreSection-section {\n  .swiper-container-android .swiper-slide,\n  .swiper-wrapper {\n    width: 100%;\n    height: 280px;\n  }\n  .card {\n    margin: 0px 5px;\n    width: 100% !important;\n    max-width: 800px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
