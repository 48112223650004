import { Typography } from '@quantumsurf/portal-shared-components'
import './../../../UseCase.scss'
import { useTranslation } from 'react-i18next'

export default function Traceability() {
  const { t } = useTranslation()

  return (
    <>
      <div className="usecasebox">
        <section className="contentbox">
          <div className="contentbox-text">
            <Typography
              style={{
                fontWeight: 'bold',
                margin: '20px 0px',
                textAlign: 'left',
              }}
              variant="h2"
            >
              {t('content.useCase.corevalues.step1.title')}
            </Typography>
          </div>
          <div className="contentbox-description">
            <li className="list-title">
              {t('content.useCase.corevalues.step1.standardization.title')}
            </li>
            <ul>
              <li>
                {t('content.useCase.corevalues.step1.standardization.desc1')}
              </li>
              <li>
                {t('content.useCase.corevalues.step1.standardization.desc2')}
              </li>
            </ul>
          </div>
          <div className="contentbox-description">
            <li className="list-title">
              {t('content.useCase.corevalues.step1.data.title')}
            </li>
            <ul>
              <li>{t('content.useCase.corevalues.step1.data.desc1')}</li>
              <li>{t('content.useCase.corevalues.step1.data.desc2')}</li>
            </ul>
          </div>
          <div className="contentbox-description">
            <li className="list-title">
              {t('content.useCase.corevalues.step1.interoperability.title')}
            </li>
            <ul>
              <li>
                {t('content.useCase.corevalues.step1.interoperability.desc1')}
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  )
}
