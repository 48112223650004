// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-management-id div {
  max-width: 1550px;
  width: 100%;
  min-width: 300px;
  display: flex;
  justify-content: center;
}
.access-management-id .slick-slider {
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/UserManagement/AppArea/apparea.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;AAAJ;AAGE;EACE,qBAAA;EACA,oBAAA;EACA,aAAA;AADJ","sourcesContent":[".access-management-id {\n  div {\n    max-width: 1550px;\n    width: 100%;\n    min-width: 300px;\n    display: flex;\n    justify-content: center;\n  }\n\n  .slick-slider {\n    padding: 0 !important;\n    margin: 0 !important;\n    display: flex;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
