// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.idp-test-container {
  width: 100%;
}
.idp-test-description {
  font-weight: bold;
  width: 80%;
  margin: auto;
  text-align: center;
}
.idp-test-steps {
  font-weight: bold;
  width: 80%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.idp-test-steps p {
  text-align: center;
}
.idp-test-info {
  width: 80%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: gray;
}
.idp-test-form {
  width: 80%;
  margin: auto;
}
.idp-test-form-submit {
  text-align: right;
}
.idp-test-form-input {
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/overlays/IDPTestRun/style.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAGE;EACE,iBAAA;EACA,UAAA;EACA,YAAA;EACA,kBAAA;AADJ;AAIE;EACE,iBAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;AAFJ;AAGI;EACE,kBAAA;AADN;AAKE;EACE,UAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;AAHJ;AAME;EACE,UAAA;EACA,YAAA;AAJJ;AAMI;EACE,iBAAA;AAJN;AAOI;EACE,mBAAA;AALN","sourcesContent":[".idp-test {\n  &-container {\n    width: 100%;\n  }\n\n  &-description {\n    font-weight: bold;\n    width: 80%;\n    margin: auto;\n    text-align: center;\n  }\n\n  &-steps {\n    font-weight: bold;\n    width: 80%;\n    margin: auto;\n    margin-top: 2rem;\n    margin-bottom: 2rem;\n    p {\n      text-align: center;\n    }\n  }\n\n  &-info {\n    width: 80%;\n    margin: auto;\n    margin-top: 2rem;\n    margin-bottom: 2rem;\n    color: gray;\n  }\n\n  &-form {\n    width: 80%;\n    margin: auto;\n\n    &-submit {\n      text-align: right;\n    }\n\n    &-input {\n      margin-bottom: 2rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
