// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
  white-space: pre-line;
}

.main-section {
  margin-bottom: 30px;
}
.main-section .company-img {
  height: 400px;
  background-color: #dcdcdc;
  background-repeat: no-repeat, no-repeat;
  background-size: contain;
  -webkit-text-decoration: center;
          text-decoration: center;
  margin-bottom: 20px;
  padding: 100px 440px;
}
.main-section h3 {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Imprint/imprint.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AACA;EAaE,mBAAA;AAVF;AAFE;EACE,aAAA;EACA,yBAAA;EACA,uCAAA;EACA,wBAAA;EACA,+BAAA;UAAA,uBAAA;EACA,mBAAA;EACA,oBAAA;AAIJ;AAFE;EACE,iBAAA;AAIJ","sourcesContent":[".text {\n  white-space: pre-line;\n}\n.main-section {\n  .company-img {\n    height: 400px;\n    background-color: #dcdcdc;\n    background-repeat: no-repeat, no-repeat;\n    background-size: contain;\n    text-decoration: center;\n    margin-bottom: 20px;\n    padding: 100px 440px;\n  }\n  h3 {\n    font-weight: bold;\n  }\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
