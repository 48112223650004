// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notice-section .card {
  max-width: 800px !important;
  width: 100% !important;
  min-width: 360px !important;
  background-color: #6b9bff;
  background-size: cover;
  height: 440px;
}
.notice-section .card .swiper-slide {
  display: "flex";
  justify-content: center;
}
.notice-section .card a {
  position: absolute;
  left: 15px;
  font-size: 18px !important;
  color: white !important;
}
.notice-section .card a:hover {
  color: #cbcbcb;
}
.notice-section .card span,
.notice-section .card h3,
.notice-section .card p {
  font-weight: 500;
  color: white;
}
.notice-section .card div h5 {
  padding-top: 20px;
  font-size: 24px;
}
.notice-section .card span {
  position: relative;
  font-size: 18px;
}
.notice-section .wrapper {
  height: 470px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/components/NoticeSection/noticesection.scss"],"names":[],"mappings":"AACE;EACE,2BAAA;EACA,sBAAA;EACA,2BAAA;EACA,yBAAA;EACA,sBAAA;EACA,aAAA;AAAJ;AAEI;EACE,eAAA;EACA,uBAAA;AAAN;AAGI;EACE,kBAAA;EACA,UAAA;EACA,0BAAA;EACA,uBAAA;AADN;AAGM;EACE,cAAA;AADR;AAKI;;;EAGE,gBAAA;EACA,YAAA;AAHN;AAOM;EACE,iBAAA;EACA,eAAA;AALR;AASI;EACE,kBAAA;EACA,eAAA;AAPN;AAWE;EACE,aAAA;AATJ","sourcesContent":[".notice-section {\n  .card {\n    max-width: 800px !important;\n    width: 100% !important;\n    min-width: 360px !important;\n    background-color: #6b9bff;\n    background-size: cover;\n    height: 440px;\n\n    .swiper-slide {\n      display: 'flex';\n      justify-content: center;\n    }\n\n    a {\n      position: absolute;\n      left: 15px;\n      font-size: 18px !important;\n      color: white !important;\n\n      &:hover {\n        color: #cbcbcb;\n      }\n    }\n\n    span,\n    h3,\n    p {\n      font-weight: 500;\n      color: white;\n    }\n\n    div {\n      h5 {\n        padding-top: 20px;\n        font-size: 24px;\n      }\n    }\n\n    span {\n      position: relative;\n      font-size: 18px;\n    }\n  }\n\n  .wrapper {\n    height: 470px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
