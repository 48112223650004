// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intro-content {
  background-color: #f9f9f9;
}
.intro-content .intro {
  display: flex;
  align-items: center;
}
.intro-content .intro .intro-text {
  width: 55%;
  margin-right: 100px;
  padding: 0px 15px 30px 10px;
}
.intro-content .intro .intro-text .intro-title {
  margin-bottom: 30px;
  font-weight: bold;
}
.intro-content .intro .intro-video {
  background-color: black;
  width: 45%;
  padding-left: 100px;
  border-radius: 16px;
}
.intro-content .intro .intro-video video {
  width: 482px;
  height: 331px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/UseCase/components/intro/usecaseintro.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;AAEJ;AADI;EACE,UAAA;EACA,mBAAA;EACA,2BAAA;AAGN;AAFM;EACE,mBAAA;EACA,iBAAA;AAIR;AADI;EACE,uBAAA;EACA,UAAA;EACA,mBAAA;EACA,mBAAA;AAGN;AAFM;EACE,YAAA;EACA,aAAA;AAIR","sourcesContent":[".intro-content {\n  background-color: #f9f9f9;\n  .intro {\n    display: flex;\n    align-items: center;\n    .intro-text {\n      width: 55%;\n      margin-right: 100px;\n      padding: 0px 15px 30px 10px;\n      .intro-title {\n        margin-bottom: 30px;\n        font-weight: bold;\n      }\n    }\n    .intro-video {\n      background-color: black;\n      width: 45%;\n      padding-left: 100px;\n      border-radius: 16px;\n      video {\n        width: 482px;\n        height: 331px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
