import { Typography } from '@quantumsurf/portal-shared-components'
import './../../../UseCase.scss'
import { useTranslation } from 'react-i18next'

export default function Contribute() {
  const { t } = useTranslation()

  return (
    <>
      <div className="usecasebox">
        <section className="contentbox">
          <div className="contentbox-text">
            <Typography
              style={{
                fontWeight: 'bold',
                margin: '20px 0px',
                textAlign: 'left',
              }}
              variant="h2"
            >
              {t('content.useCase.corevalues.step2.title')}
            </Typography>
          </div>
          <div
            className="contentbox-description"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2,1fr)',
              gap: '20px',
            }}
          >
            <div className="tracing-list">
              <li className="list-title">
                {t('content.useCase.corevalues.step2.tracing.title')}
              </li>
              <ul>
                <li>{t('content.useCase.corevalues.step2.tracing.desc1')}</li>
                <li>{t('content.useCase.corevalues.step2.tracing.desc2')}</li>
                <li>{t('content.useCase.corevalues.step2.tracing.desc3')}</li>
              </ul>
            </div>
            <div className="provide">
              <li className="list-title">
                {t('content.useCase.corevalues.step2.provide.title')}
              </li>
              <ul>
                <li>{t('content.useCase.corevalues.step2.provide.desc1')}</li>
                <li>{t('content.useCase.corevalues.step2.provide.desc2')}</li>
                <li>{t('content.useCase.corevalues.step2.provide.desc3')}</li>
                <li>{t('content.useCase.corevalues.step2.provide.desc4')}</li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
