import { Typography } from '@quantumsurf/portal-shared-components'
import './../../../UseCase.scss'
import { useTranslation } from 'react-i18next'

export default function Explanation() {
  const { t } = useTranslation()

  return (
    <>
      <div className="usecasebox">
        <section className="contentbox">
          <div className="contentbox-text">
            <Typography
              style={{
                fontWeight: 'bold',
                margin: '20px 0px',
                textAlign: 'left',
              }}
              variant="h2"
            >
              {t('content.useCase.corevalues.step3.title')}
            </Typography>
          </div>
          <div className="contentbox-description">
            <Typography variant="h4">
              {t('content.useCase.corevalues.step3.bpn.title')}
            </Typography>
            <Typography>
              {t('content.useCase.corevalues.step3.bpn.desc')}
            </Typography>
            <Typography variant="h4">
              {t('content.useCase.corevalues.step3.edc.title')}
            </Typography>
            <Typography>
              {t('content.useCase.corevalues.step3.edc.desc')}
            </Typography>
            <Typography variant="h4">
              {t('content.useCase.corevalues.step3.digitaltwin.title')}
            </Typography>
            <Typography>
              {t('content.useCase.corevalues.step3.digitaltwin.desc')}
            </Typography>
            <Typography variant="h4">
              {t('content.useCase.corevalues.step3.UUID.title')}
            </Typography>
            <Typography>
              {t('content.useCase.corevalues.step3.UUID.desc')}
            </Typography>
            <Typography variant="h4">
              {t('content.useCase.corevalues.step3.localidentifiers.title')}
            </Typography>
            <Typography>
              {t('content.useCase.corevalues.step3.localidentifiers.desc')}
            </Typography>
            <Typography variant="h4">
              {t('content.useCase.corevalues.step3.aspect.title')}
            </Typography>
            <Typography>
              {t('content.useCase.corevalues.step3.aspect.desc')}
            </Typography>
          </div>
        </section>
      </div>
    </>
  )
}
