import Traceability from './Component/traceability'
import Contribute from './Component/contribute'
import Explanation from './Component/explanation'

export default function UsecaseCoreValues() {
  return (
    <>
      <div>
        <Traceability />
        <Contribute />
        <Explanation />
      </div>
    </>
  )
}
