import {
  Typography,
  StaticTable,
  type TableType,
} from '@quantumsurf/portal-shared-components'
import './../../../UseCase.scss'
import './inside.scss'
import { useTranslation } from 'react-i18next'

export default function Inside() {
  const { t } = useTranslation('', {
    keyPrefix: 'content.useCase.details',
  })
  const asbuilttable: TableType = {
    head: [
      t('step1.built.serialpart.title'),
      t('step1.built.batch.title'),
      t('step1.built.singielevelbomasbuilt.title'),
    ],
    body: [
      [t('step1.built.serialpart.desc')],
      [t('step1.built.batch.desc')],
      [t('step1.built.singielevelbomasbuilt.desc')],
    ],
  }
  // const asplannedtable = {
  //     head: [t('content.useCase.details.step1.planned.partasplanned.title')],
  //     body: [t('content.useCase.details.step1.planned.partasplanned.desc')],
  // }
  const asplannedtable: TableType = {
    head: [
      t('step1.planned.partasplanned.title'),
      t('step1.planned.singlelevelbomplanned.title'),
      t('step1.planned.singlelevelusageplanned.title'),
      t('step1.planned.partsiteinformationasplanned.title'),
    ],
    body: [
      [t('step1.planned.partasplanned.desc')],
      [t('step1.planned.singlelevelbomplanned.desc')],
      [t('step1.planned.singlelevelusageplanned.desc')],
      [t('step1.planned.partsiteinformationasplanned.desc')],
    ],
  }
  return (
    <>
      <div className="detailbox">
        <section className="contentbox">
          <div className="contentbox-text">
            <Typography
              style={{
                fontWeight: 'bold',
                margin: '20px 0px',
                textAlign: 'left',
              }}
              variant="h2"
            >
              {t('step1.title')}
            </Typography>
            <fieldset className="detailset">
              <legend>{t('step1.built.title')}</legend>
              <Typography>{t('step1.built.desc')}</Typography>
              <StaticTable horizontal={true} data={asbuilttable} />
            </fieldset>
            <fieldset className="detailset">
              <legend>{t('step1.planned.title')}</legend>
              <Typography>{t('step1.planned.desc')}</Typography>
              <StaticTable horizontal={true} data={asplannedtable} />
            </fieldset>
          </div>
        </section>
      </div>
    </>
  )
}
